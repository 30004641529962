import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
    name    : 'user-students-tariffs',
    mixins  : [validationMixin],
    data () {
        return {
            valid           : false,
            page            : 1,
            limitTariffs    : 100,
            showDialogTariff: false,
            showRemoveDialog: false,
            deletedItem     : null,
            editedItem      : null,
            keywords        : '',
            payload         : {
                tariffId        : '',
                isPaid          : false
            },
            availableTo     : '',
            search          : null,
            searchInput     : null,
            dateInvalid     : false
        }
    },
    computed  : {
        ...mapGetters({
            userTariffs           : 'userTariffs/userTariffs',
            userTariffsLength     : 'userTariffs/userTariffsLength',
            isLoadingUserTariffs  : 'userTariffs/isLoadingUserTariffs',
            limit                 : 'userTariffs/limit',
            skip                  : 'userTariffs/skip',
            isLoading             : 'userTariffs/isLoading',
            tariffs               : 'userTariffs/tariffs',
            isTariffsLoading      : 'userTariffs/isTariffsLoading'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'id'
                },
                {
                    text    : 'Tariff ID',
                    value   : 'tariff_id'
                },
                {
                    text    : 'Name',
                    value   : 'name'
                },
                {
                    text    : 'Type',
                    value   : 'type'
                },
                {
                    text    : 'Status',
                    value   : 'status'
                },
                {
                    text    : 'Renewal',
                    value   : 'is_renewal'
                },
                {
                    text    : 'Days',
                    value   : 'days'
                },
                {
                    text    : 'Created at',
                    value   : 'created_at'
                },
                {
                    text    : 'Updated at',
                    value   : 'updated_at'
                },
                {
                    text    : 'Active to',
                    value   : 'active_to'
                },
                {
                    text    : 'Actions',
                    value   : 'actions',
                    sortable: false
                }
            ]
        }
    },
    watch: {
        availableTo: {
            handler(val, oldVal) {
                this.validateDate();
            },
            deep: true
        }
    },
    created () {
        if (!this.userTariffs.length) {
            this.fetchUserTariffs({
                userId  : this.$route.params.id
            })
        }
        
        if (this.userTariffs) {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        }
        
        if (!this.tariffs.length){
            this.fetchTariffs({
                keywords    : '',
                loadAll     : true
            })
        }
    },
    methods: {
        ...mapActions({
            fetchTariffs    : 'userTariffs/GET_TARIFFS',
            fetchUserTariffs: 'userTariffs/GET_USER_TARIFFS',
            remove          : 'userTariffs/DELETE',
            create          : 'userTariffs/CREATE',
            update          : 'userTariffs/UPDATE',
            renewal       	: 'userTariffs/RENEWAL'
        }),
        ...mapMutations({
            changeSkip      : 'userTariffs/CHANGE_SKIP',
            setUserTariffs  : 'userTariffs/SET_USER_TARIFFS',
            setTariffs      : 'userTariffs/SET_TARIFFS'
        }),
        changeInputTariff: debounce(function (val) {
            if (!val) return;
            
            if(this.search && this.search.name == val){
                this.keywords = val;
                return;
            }
            
            if (this.keywords !== val) {
                this.keywords = val;
                
                if(false){
                    this.fetchTariffs({
                        keywords: val,
                        id      : this.payload.tariffId,
                        //limit   : this.limitTariffs
                    })
                }
            }
        }, 500),
        autoCompleteChanged (e) {
            this.payload.tariffId   = e.id;
            this.search             = e;
        },
        changeDialogAction (item = null, status = false) {
            this.showDialogTariff   = status;
            this.editedItem         = item;
            
            if (!status) {
                this.payload.tariffId       = '';
                this.availableTo            = '';
                
                this.search         = null;
                this.searchInput    = null;
            }
            
            if (status && item != null) {
                this.search         = null;
                this.searchInput    = null;
                this.keywords       = item.tariff.data.name;
                
                this.payload.tariffId       = item.tariff_id;
                this.availableTo            = item.active_to;
            }
        },
        removeItem () {
            this.remove({
                userId  : this.$route.params.id,
                id      : this.deletedItem.tariff_id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.fetchUserTariffs({
                    userId  : this.$route.params.id
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItem        = item;
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            
            this.$vuetify.goTo(0);
            
            this.fetchUserTariffs({
                userId  : this.$route.params.id,
                limit   : 300
            });
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        createRequestPayload () {
            const formData = new FormData();
            
            formData.append('available_to'  , this.formattedDate(this.availableTo));
            formData.append('userId'        , this.$route.params.id);
            
            if (this.editedItem) {
                formData.append('_method'       , 'patch');
                formData.append('id'            , this.editedItem.id);
            }else{
                formData.append('tariff_id'     , this.payload.tariffId);
                formData.append('is_paid'       , this.payload.isPaid ? 1 : 0);
            };
            
            return formData;
        },
        validateDate(){
            if(this.availableTo){
                const currentDate = new Date();
                
                if(this.availableTo < currentDate){
                    this.dateInvalid = true;
                }else{
                    this.dateInvalid = false;
                }
            }else{
                this.dateInvalid = false;
            }
        },
        submit () {
            this.$refs.form.validate();
            this.$refs.observer.validate();
            
            if (this.valid) {
                this.validateDate();
                
                if(this.dateInvalid){
                    return false;
                };
                
                if (this.editedItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('userSuccessUpdate'));
                        
                        this.changeDialogAction();
                    })
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('userSuccessUpdate'));
                        
                        this.changeDialogAction();
                    })
                }
            }
        },
        handleCheckboxChange (newValue, item) {
			console.log('newValue:');
			console.log(newValue);
			
			console.log('item:');
			console.log(item);
			
			//return false;
			
			const formData = new FormData();
			
			formData.append('_method'		, 'patch');
			formData.append('userId'		, item.user_id);
			formData.append('id'			, item.id);
			formData.append('is_renewal'	, newValue ? 1 : 0);
			formData.append('isRenewal'		, newValue ? 1 : 0);
			
			console.log('formData:');
			console.log(formData);
			
			this.renewal(formData)
			.then(() => {
				this.$toasted.success(this.$t('Success'));
			})
			.catch(() => {
				this.$toasted.error(this.$t('Error'));
			})
		}
    },
    destroyed () {
        this.setUserTariffs([]);
        this.setTariffs([]);
    }
}
